import { makeStyles } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles'

const useStyles = props => makeStyles(theme => {

    return ({
        tab: {
            color: theme.palette.common.blackfaded,
        },
        activetab: {
            color: theme.palette.common.black
        },
        uploadpanelcontainer: {
            backgroundColor: theme.palette.background.alternate,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%'
        },
        uploadpanelheader: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-around',
            '& h2': {
            },
        },
        uploadpanelbody: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flexStart',
            padding: '1em 2em'
        }
    })
})

export default useStyles
