import { useContext, createContext } from 'react'
import Firebase from './Firebase'

// Context
const FirebaseContext = createContext(null)

// Hook
export const useFirebase = () => {
    return useContext(FirebaseContext)
}

// Provider ////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function FirebaseProvider(props) {
    const firebase = new Firebase()

    return (
        <FirebaseContext.Provider value={{ firebase }}>
            { props.children }
        </FirebaseContext.Provider>
    )
}
