import Grid from '@material-ui/core/Grid'
import { useState } from 'react'

import Dropzone from 'common/components/Dropzone'
import ContractViewer from './ContractViewer'
import ContractPanel from './ContractPanel'

export default function ContractUpload() {
    const [categories, setCategories] = useState({})
    const [activetab, setActiveTab] = useState('queue')

    function handleAddFiles(files) {
        let newfiles = files.map(f => ({ file: f, selected: false }))
        setCategories(
            categories.hasOwnProperty('Unassigned')
            ? { ...categories, 'Unassigned': categories.Unassigned.concat(newfiles) }
            : { ...categories, 'Unassigned': newfiles }
        )
    }

    function handleChangeTab(tab) {
        setActiveTab(tab)
    }

    function handleFileSelect(category, index) {
        setCategories({ ...categories, [category]: categories[category].map((f, i) => index === i ? { ...f, selected: !f.selected } : f) })
    }

    function handleCategorySelect(category) {
        let select = !categories[category].every(f => f.selected)
        setCategories({ ...categories, [category]: categories[category].map(f => ({...f, selected: select})) })
    }

    function handleUnselectAll() {
        let unselectedcategories = {}
        for (let category of Object.keys(categories)) {
            unselectedcategories[category] = categories[category].map(f => ({ file: f.file, selected: false }))
        }
        setCategories(unselectedcategories)
    }

    function handleSelectAll() {
        let selectedcategories = {}
        for (let category of Object.keys(categories)) {
            selectedcategories[category] = categories[category].map(f => ({ file: f.file, selected: true }))
        }
        setCategories(selectedcategories)
    }

    function handleDeleteSelected() {
        let filteredcategories = {}
        for (let category of Object.keys(categories)) {
            let files = categories[category].filter(f => !f.selected)
            if (files.length) {
                filteredcategories[category] = files
            }
        }
        setCategories(filteredcategories)
    }

    return (
        <Grid
            container
            alignItems="stretch"
            style={{ minHeight: '100vh' }}
        >
            <Grid
                container
                item
                direction="column"
                alignItems="center"
                xs={4}
            >
                {/* <br />
                <h3>Contract Previewer</h3>
                {files.map((file, index) => (
                    <p key={index}>
                        { file.name }
                    </p>
                ))} */}
                <ContractPanel
                    categories={categories}
                    activetab={activetab}
                    onChangeTab={handleChangeTab}
                    onFileSelect={handleFileSelect}
                    onCategorySelect={handleCategorySelect}
                    onSelectAll={handleSelectAll}
                    onUnselectAll={handleUnselectAll}
                    onDeleteSelected={handleDeleteSelected}
                />
            </Grid>
            <Grid
                container
                item
                direction="column"
                alignItems="center"
                xs={8}
                style={{ backgroundColor: 'white' }}
            >
                { activetab === 'queue'
                ? (
                    <Dropzone
                        onAddFiles={handleAddFiles}
                    />
                ) : (
                    <ContractViewer
                        contract={Object.keys(categories).length ? categories[Object.keys(categories)[0]][0].file : null}
                        src="/mypdf.pdf"
                    />
                ) }
            </Grid>
        </Grid>
    )
}




// // Refactored this is a lot easier to read, more modular, and faster!

// const initialState = {
//     categories: {
//         'category1': [
//             { file: file, selected: false },
//             { file: file2, selected: false },
//         ]
//     }
// }

// const reducer = (state, action) => {
//     switch (action.type) {
//         case 'setIsOpen':
//             return { ...state, isOpen: action.payload }
//             break;
//         // ... 
//         default:
//             return state;
//     }
// }
