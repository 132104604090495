import { makeStyles } from '@material-ui/core/styles'

const useStyles = props => makeStyles(theme => ({
    root: {
        color: props.color,
        backgroundColor: props.backgroundColor,
        width: props.width,
        textTransform: 'none',
        height: '3em',
        boxShadow: props.boxShadow,
        margin: `${theme.spacing(1)}px ${theme.spacing(.5)}px`,
        '&:hover': {
            color: props.hoverColor,
            backgroundColor: props.hoverBackgroundColor,
        },
    },
}))

export default useStyles
