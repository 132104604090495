import { useState } from 'react'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router-dom'

import ContentSection from 'common/components/ContentSection'
import PropertyListHeader from '../PropertyListHeader'
import PropertyListTable from '../PropertyListTable'
import usePropertyList from './usePropertyList'
import { CircularProgress } from '@material-ui/core'

// Component
export default function PropertyList() {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const history = useHistory()
    const { propertyList, loading } = usePropertyList()

    // List of filter tabs with the associated program types to filter on for the property
    const tabs = [
        { label: 'View All', programTypes: ['Bulk', 'Rev Share', 'Other'] },
        { label: 'Bulked', programTypes: ['Bulk'] },
        { label: 'Rev Shared', programTypes: ['Rev Share'] },
        { label: 'Other', programTypes: ['Other'] },
    ]

    function handlePropertySelect(id) {
        history.push('/properties/' + id)
    }

    return loading ? <></> : (
        <ContentSection header='Properties'>
            <Box mb={3}>
                <PropertyListHeader
                    tabs={tabs.map(tab => tab.label)}
                    onTabSelect={(index) => setSelectedIndex(index)}
                />
            </Box>
            <Box my={3}>
                <PropertyListTable
                    properties={propertyList}
                    onPropertySelect={handlePropertySelect}
                />
            </Box>
        </ContentSection>
    );
}
