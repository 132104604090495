import { useState, useEffect } from 'react'
import usePersistentState from './usePersistentState'

// Callback is the function to be called on successful form submit
// Fields is an object:
// {
//     "Field 1": {
//         persist: true                    (false by default or if key is missing)
//         validateon: 'change'             (only validated on submit by default or if key is missing)
//         validations: {
//             max: 10                                  (pass as value for default error message)
//             min: [2, 'Field 1 must be less than 2']  (pass as array for custom error message)
//
//             (custom validation function to run - returns error message on validation fail & null on validation pass)
//             (custom functions will always take 2 parameters, 1 is the value of the field, and 2 is the values object for other fields in the form)
//             custom: (val, values) => val !== values['Password'] ? 'Confirm Password field must be the same as Password' : null
//             custom: (val, values) => { // Another validation function }
//             ...other validation functions
//         }
//     }
// }
// Persistent key is the local storage key to use if any of the form fields are meant to be persisted (in most cases should be unique per form)
const useForm = (callback, fields = {}, persistentKey = null) => {
    let initialvalues = {}
    let initialpersistentvalues = {}
    for (const [key, value] of Object.entries(fields)) {
        if (value.hasOwnProperty('persist') && value.persist) {
            if (persistentKey === null) throw new Error('Must provide persistent local storage key for persistent form values')
            initialpersistentvalues[key] = value.default
        }
        initialvalues[key] = value.default
    }

    const [values, setValues] = useState(initialvalues)
    const [errors, setErrors] = useState({})
    const [persistentvalues, setPersistentValues] = usePersistentState(persistentKey, {})
    const allvalues = { ...values, ...persistentvalues }

    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback()
        }
    // TODO - Fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors])


    const setFieldError = (field, value, errors) => {
        if (fields.hasOwnProperty(field) && fields[field].hasOwnProperty('validations')) {
            for (const validation of fields[field].validations) {
                let funcname = Object.keys(validation)[0]
                let error = null
                let customerror = null
                let validationvalue = validation[funcname]
                if (Array.isArray(validationvalue)) {
                    customerror = validationvalue[1]
                    validationvalue = validationvalue[0]
                }
    
                switch (funcname) {
                    case 'required':
                        if (!value)
                            error = customerror ?? `${field} is required`
                        break
                    case 'min':
                        if (value < validationvalue)
                            error = customerror ?? `${field} must be greater than ${validationvalue}`
                        break
                    case 'max':
                        if (value > validationvalue)
                            error = customerror ?? `${field} must be less than ${validationvalue}`
                        break
                    case 'minLength':
                        if (!value || value.length < validationvalue)
                            error = customerror ?? `${field} must be at least ${validationvalue} characters long`
                        break
                    case 'maxLength':
                        if (!value || value.length > validationvalue)
                            error = customerror ?? `${field} must be less than ${validationvalue} characters long`
                        break
                    case 'regex':
                        let re = new RegExp(validationvalue)
                        if (!re.test(value))
                            error = customerror ?? `${field} is invalid`
                            break
                    case 'custom':
                        error = validationvalue(value, allvalues)
                        break
                    default:
                        break
                }
    
                if (error) {
                    if (!errors.hasOwnProperty(field))
                        errors[field] = { [funcname]: error }
                    else
                        errors[field][funcname] = error
                }
                else if (errors.hasOwnProperty(field) && errors[field].hasOwnProperty(funcname)) {
                    delete errors[field][funcname]
                }
            }
        }

        return errors
    }


    // Run Validations for fields passed in
    const validateFields = (fieldstovalidate) => {
        let errors = {}

        fieldstovalidate.forEach(field => {
            let value = fields[field].persist === true ? persistentvalues[field] : values[field]

            errors = setFieldError(field, value, errors)
        })

        return errors
    }


    const validateField = (field, value) => {
        return setFieldError(field, value, errors)//JSON.parse(JSON.stringify(errors)))
    }


    // Validate all fields and submit
    const handleSubmit = (event) => {
        if (event) event.preventDefault()
        setIsSubmitting(true)
        setErrors(validateFields(Object.keys(fields)))
    }


    // Update the value (persisted or not) then set errors depending on the field
    const handleChange = (event) => {
        event.persist()
        let field = event.target.name
        if (!field)
            throw new Error('Input requires a "Name" attribute to handle changes')

        // Determine whether to persist updated value
        if (fields.hasOwnProperty(field) && fields[field].persist === true) {
            setPersistentValues(persistentvalues => ({ ...persistentvalues, [field]: event.target.value }))
        }
        else {
            setValues(values => ({ ...values, [field]: event.target.value }))
        }

        // Conditionally run validation for the field
        if (fields.hasOwnProperty(field) && (errors.hasOwnProperty(field) || fields[field].validateon === 'change')) {
            let value = event.target.value
            setErrors(errors => validateField(field, value))
        }
    }

    const clearForm = () => {
        setValues(initialvalues)
        setPersistentValues(initialpersistentvalues)
        if (Object.keys(errors).length > 0)
            setErrors({})
    }

    return {
        handleChange,
        handleSubmit,
        clearForm,
        values: allvalues,
        errors: new Errors(errors),
    }
}

export default useForm

class Errors {
    constructor(errors) {
        this.errors = errors
    }

    first(field) {
        if (this.errors.hasOwnProperty(field)) {
            for (const [key, value] of Object.entries(this.errors[field])) {
                return value
            }
        }
        return null
    }

    has(field) {
        return this.errors.hasOwnProperty(field)
    }
}
