import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { useState } from 'react'

import useStyles from './ContractPanelFileList.styles.js'
import Button from 'common/components/Button'
import Checkbox from '@material-ui/core/Checkbox'

export default function ContractPanelFileList(props) {
    const styles = useStyles(props)()

    function renderIcon(filetype) {
        switch (filetype) {
            case 'application/pdf':
                return (
                    <img
                        src='/pdf.svg'
                        width={30}
                        height={30}
                        alt='pdf'
                    />
                )
            case 'image/png':
                return (
                    <img
                        src='/png.svg'
                        width={30}
                        height={30}
                        alt='png'
                    />
                )
            case 'image/svg+xml':
                return (
                    <img
                        src='/svg.svg'
                        width={30}
                        height={30}
                        alt='svg'
                    />
                )
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return (
                    <img
                        src='/doc.svg'
                        width={30}
                        height={30}
                        alt='doc'
                    />
                )
            case 'application/vnd.ms-excel':
            return (
                <img
                    src='/xls.svg'
                    width={30}
                    height={30}
                    alt='xls'
                />
            )
            case 'text/plain':
                return (
                    <img
                        src='/txt.svg'
                        width={30}
                        height={30}
                        alt='text'
                    />
                )
            default:
                return (
                    <img
                        src='/file.svg'
                        width={30}
                        height={30}
                        alt='file'
                    />
                )
        }
    }

    return (
        <div className={styles.filelistcontainer}>
            { Object.keys(props.categories).map((category, i) => (
                <div key={i}>
                    <div className={styles.row}>
                        { props.selectable ? (
                            <Checkbox
                                checked={props.categories[category].every(f => f.selected)}
                                indeterminate={props.categories[category].some(f => f.selected) && props.categories[category].some(f => !f.selected)}
                                onChange={() => props.onCategorySelect(category)}
                            />
                        ) : null }
                        <h3>{ category } ({ props.categories[category].length })</h3>
                    </div>
                    { props.categories[category].map((f, index) => (
                        <div className={styles.row} key={index}>
                            { props.selectable ? (
                                <Checkbox
                                    checked={f.selected}
                                    onChange={() => props.onFileSelect(category, index)}
                                />
                            ) : null }
                            <Box
                                px={2}
                                py={2}
                                my={1}
                                component={Paper}
                                className={styles.filecontainer}
                            >
                                { renderIcon(f.file.type) }
                                <span className={styles.filename}>
                                    { f.file.name.substr(0, f.file.name.lastIndexOf('.')) || f.file.name }
                                </span>
                            </Box>
                        </div>
                    )) }
                </div>
            )) }
        </div>
    )
}
