import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    headerRow: {
        backgroundColor: 'white',
    },
    tableRow: {
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.lightFaded
        }
    },
}))

export default useStyles
