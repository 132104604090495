import { Route } from 'react-router-dom'

// Wrap Route content with the provided layout (preserves state between route changes)
export default function LayoutRoute({
    component: Component,
    layout: Layout,
    ...rest
}) {
    return (Layout === null || Layout === undefined)
    ? (
        <Route {...rest} render={(props) => Component} />
    ) : (
        <Route {...rest} render={(props) =>
            <Layout {...props}>
                {Component}
            </Layout>
        } />
    )
}
