import { useApi } from 'api/ApiProvider'
import { useState, useEffect } from 'react'

export default function usePropertyList() {
    const [propertyList, setPropertyList] = useState({
        propertyList: [],
        error: null
    })
    const [loading, setLoading] = useState(true)
    const { propertyclient: { getPropertyList } } = useApi()

    useEffect(() => {
        let active = true
        getPropertyList(16)
            .then(propertyList => {
                if (active) {
                    setPropertyList({ propertyList, error: null })
                    setLoading(false)
                }
            })
            .catch(error => {
                if (active) {
                    setPropertyList({ propertyList: [], error })
                    setLoading(false)
                }
            })

            return () => { active = false }
    }, [getPropertyList])

    return { propertyList: propertyList.propertyList, error: propertyList.error, loading }
}
