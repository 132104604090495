import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import ViewCarouselOutlinedIcon from '@material-ui/icons/ViewCarouselOutlined'
import { withRouter } from 'react-router-dom'

import { Link } from 'common/components/Routing'
import styles from './Navbar.module.scss'

// Component
function Navbar(props) {
    const tabs = [
        { 'label': 'Properties', 'icon': <LayersOutlinedIcon />, 'route': '/properties' },
        { 'label': 'Contracts', 'icon': <ViewCarouselOutlinedIcon />, 'route': '/contracts' },
        { 'label': 'Profile', 'icon': <PersonOutlineOutlinedIcon />, 'route': '/profile' },
    ]

    // Check that the current navigation's base route matches the tab's route
    function doesRouteMatch(route) {
        if (
            props.location.pathname.length >= route.length
            && props.location.pathname.substring(0, route.length) === route
            && (
                props.location.pathname.length === route.length
                || props.location.pathname.substring(route.length, route.length + 1) === '/'
            )
        ) { return true }

        return false
    }

    return (
        <Drawer
            className={styles.drawer}
            variant='permanent'
            classes={{ paper: styles.drawerPaper }}
            anchor='left'
        >
            <div className={styles.logo}>
                <img
                    src='/darkLogo_xl.png'
                    width={155}
                    height={28}
                    alt='Onboard RS'
                />
            </div>
            <List>
                {tabs.map((tab, index) => (
                    <Link to={tab.route} key={index}>
                        <ListItem
                            button
                            key={tab.label}
                            className={ doesRouteMatch(tab.route) ? styles.listItemActive : styles.listItem}
                        >
                            <ListItemIcon className={ doesRouteMatch(tab.route) ? styles.listItemIconActive : styles.listItemIcon }>
                                { tab.icon }
                            </ListItemIcon>
                            <ListItemText
                                primary={tab.label}
                                classes={{ primary: doesRouteMatch(tab.route) ? styles.listItemTextActive : styles.listItemText }}
                            />
                        </ListItem>
                    </Link>
                ))}
            </List>
        </Drawer>
    )
}

export default withRouter(Navbar)
