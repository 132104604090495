import Button from 'common/components/Button'
import ContentSection from 'common/components/ContentSection'
import { Link } from 'common/components/Routing'

export default function Contracts() {
    return (
        <ContentSection header="Contracts Page">
            <Link to="/contracts/upload">
                <Button>
                    Upload New Contracts
                </Button>
            </Link>
        </ContentSection>
    )
}
