import { Link } from 'react-router-dom'

// React Router Link with styles removed
export default function UnstyledLink(props) {
    return (
        <Link
            {...props}
            style={{ color: 'inherit', textDecoration: 'none' }}
        >
            { props.children }
        </Link>
    )
}
