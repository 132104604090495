import { makeStyles } from '@material-ui/core/styles'

const useStyles = props => makeStyles(theme => {
    return ({
        switch: {
            display: 'flex',
            fontWeight: 'bold',
            boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
        },
        switchActive: {
            color: theme.palette.common.gray,
            backgroundColor: theme.palette.common.black,
            width: '3.5em',
            padding: '.3em 0',
            textAlign: 'center',
        },
        switchInactive: {
            color: theme.palette.common.black,
            backgroundColor: theme.palette.common.gray,
            width: '3.5em',
            padding: '.3em 0',
            textAlign: 'center'
        },
        selectcontainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: props.selectable ? 'space-between' : 'flex-start'
        },
        helpertext: {
            fontWeight: 'bold',
            color: theme.palette.common.blackfaded,
            margin: '0 .5em'
        },
    })
})

export default useStyles
