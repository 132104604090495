import Button from '@material-ui/core/Button'

import ContentSection from "common/components/ContentSection"
import PaddedLayout from "common/layouts/PaddedLayout"
import { useApi } from 'api/ApiProvider'
import { useAuth } from 'authentication/AuthProvider'
import { useHistory } from 'react-router-dom'

export default function Profile() {
    const { initialuser, setUser } = useAuth()
    const { authclient: { signOut } } = useApi()
    const history = useHistory()

    function handleSignOut() {
        signOut().then(res => {
            setUser(initialuser)
            history.push('/')
        })
    }

    return (
        <PaddedLayout>
            <ContentSection header="Profile">
                <Button onClick={handleSignOut}>Log Out</Button>
            </ContentSection>
        </PaddedLayout>
    )
}
