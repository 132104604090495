import { Switch } from 'react-router-dom'

import { LayoutRoute } from 'common/components/Routing'
import PaddedLayout from 'common/layouts/PaddedLayout'
import Properties from 'pages/Properties'
import Property from 'pages/Property'
import PropertyCreate from 'pages/PropertyCreate'

export default function PropertiesRoutes() {
    return (
        <Switch>
            <LayoutRoute exact path='/properties' layout={PaddedLayout} component={<Properties />} />
            <LayoutRoute exact path='/properties/create' layout={PaddedLayout} component={<PropertyCreate />} />
            <LayoutRoute exact path='/properties/:id' layout={PaddedLayout} component={<Property />} />
        </Switch>
    )
}
