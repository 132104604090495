import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import styles from './ContentSection.module.scss'

// Component
export default function ContentSection(props) {
    return (
        <Grid
            container
            item
            direction='column'
        >
            <Box my={2}>
                <h2>{props.header}</h2>
                <div className={styles.contentSection}>
                    { props.children }
                </div>
            </Box>
        </Grid>
    );
}
