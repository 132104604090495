import { useLocation, Redirect } from 'react-router-dom'
import { useAuth } from './AuthProvider'

export function WithAuthentication(props) {
    const { user } = useAuth()
    const location = useLocation()

    return props.children
    // TODO - change to check JWT
    // return user.auth
    // ? props.children
    // : <Redirect to={{ pathname: '/', state: { redirectOnLoginPathname: location.pathname }}} />
}

const withAuthentication = Component => ({ ...props }) => (
    <WithAuthentication>
        <Component {...props} />
    </WithAuthentication>
)

export default withAuthentication
