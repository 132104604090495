import { useState } from 'react'
import { FormControl, InputLabel, MenuItem, FormHelperText } from '@material-ui/core'
import MuiSelect from '@material-ui/core/Select'
import useStyles from './Select.styles'

export default function Select(props) {
    const classes = useStyles(props)()
    const [id] = useState(uniqueId('prefix-'))
    const { options, value, label, onChange } = props
    console.log(options)

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id={id}>{ label }</InputLabel>
            <MuiSelect
                labelId={id}
                value={ value === '' ? '-' : value }
                onChange={onChange}
                variant="outlined"
            >
                <MenuItem key={uniqueId('prefix-')} value='-'>-</MenuItem>
                { options.map((option, index) => (
                    <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                )) }
            </MuiSelect>
            { /* <FormHelperText>Some important helper text</FormHelperText> */ }
        </FormControl>
    )
}

// Generate unique ids to dynamically associate input label with input base
function gen4() {
    return Math.random().toString(16).slice(-4)
}

function uniqueId(prefix) {
    return (prefix || '').concat([
        gen4(),
        gen4(),
        gen4(),
        gen4(),
        gen4(),
        gen4(),
        gen4(),
        gen4()
    ].join(''))
}
