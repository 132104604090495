import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router'

import { Link } from 'common/components/Routing'
import ContentSection from 'common/components/ContentSection'
import PropertyContractListHeader from '../PropertyContractListHeader'
import PropertyContractListTable from '../PropertyContractListTable'
import { usePropertyContractList } from './usePropertyContractList'

export default function PropertyContractList(props) {
    const history = useHistory()
    const { contractPropertyList, loading } = usePropertyContractList()

    function handleContractSelect(id) {
        history.push('/properties/' + id)
    }

    return loading ? <></> : (
        <ContentSection header='Contracts'>
            <Box mb={3}>
                <PropertyContractListHeader />
            </Box>
            <Box my={3}>
                <PropertyContractListTable
                    contracts={contractPropertyList}
                    onPropertySelect={handleContractSelect}
                />
            </Box>
        </ContentSection>
        // <Grid container>
        //     <Grid item xs={3}>
        //         <Box mx={1}>
        //             <Link to="/contracts/upload">
        //                 <Paper elevation={0} style={{ backgroundColor: 'transparent', border: '1px dashed gray', marginTop: '1em' }}>
        //                     <p style={{ height: '23em', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'gray' }}>Add Contract</p>
        //                 </Paper>
        //             </Link>
        //         </Box>
        //     </Grid>
        //     { props.contracts.map((contract, index) => (
        //         <Grid key={index} item xs={3}>
        //             <Box component={Paper} mx={1}>
        //                 <p style={{ height: '25em', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{contract}</p>
        //             </Box>
        //         </Grid>
        //     )) }
        // </Grid>
    )
}
