import { useRef } from 'react'
import useStyles from './ContractViewer.styles.js'

export default function ContractViewer(props) {
    const styles = useStyles(props)()

    return (
        <div
            // id='pdfviewer'
            // ref={viewerref}
            // style={{ width: '100%', height: '100%' }}
        >
            { props.contract
            ? (
                <embed
                    src={window.URL.createObjectURL(props.contract)}
                    width="795px"
                    height="600px"
                />
            ) : (
                <p>Please upload a file to preview</p>
            ) }
        </div>
    )
}
