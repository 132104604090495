import firebase from 'firebase'
import 'firebase/auth'

export default class Firebase {
    constructor() {
        if (firebase.apps.length === 0) {
            firebase.initializeApp({
                apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
                authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
                projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
                storageBucket: process.env.REACT_STORAGE_BUCKET,
                appId: process.env.REACT_APP_FIREBASE_APP_ID,
                measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
                messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
            })
        }

        this.EmailAuthProvider = firebase.auth.EmailAuthProvider
        this.auth = firebase.auth()
    }
}
