const fields = {
    'Name': {
        default: '',
        validations: [
            { required: true },
        ],
        persist: true
    },
    'Address Line 1': {
        default: '',
        validations: [
            { required: true },
        ],
        persist: true
    },
    'Address Line 2': {
        default: '',
        persist: true
    },
    'City': {
        default: '',
        validations: [
            { required: true },
        ],
        persist: true
    },
    'State': {
        default: '',
        validations: [
            { required: true },
            { minLength: 2 },
        ],
        persist: true
    },
    'Zip Code': {
        default: '',
        validations: [
            { required: true },
            { minLength: 5 },
        ],
        persist: true
    },
    'Country': {
        default: '',
        validations: [
            { required: true },
            { minLength: 2 },
        ],
        persist: true
    },
    'Unit Count': {
        default: '',
        validations: [
            { required: true },
            { min: 1 }
        ],
        persist: true
    },
}

export default fields
