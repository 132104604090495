import { makeStyles } from '@material-ui/core/styles'

const useStyles = props => makeStyles(theme => {
    return ({
        filelistcontainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
        },
        filecontainer: {
            display: 'flex',
            alignItems: 'center',
        },
        filename: {
            margin: '0 1em'
        },
        row: {
            display: 'flex',
        }
    })
})

export default useStyles
