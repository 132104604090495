import { createTheme } from '@material-ui/core/styles'

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: '#ff4518',
            faded: '#ff7d5e',
            lightFaded: '#f7f7f7'
        },
        secondary: {
            main: '#9C003E',
        },
        common: {
            white: '#ffffff',
            black: 'rgb(90, 90, 90)',
            blackfaded: 'rgba(90, 90, 90, 0.4)',
            gray: '#F1F1F1',
        },
        error: {
            main: '#B20000',
        },
        background: {
            default: '#FFF7F0',
            alternate: '#FFFCF0',
        },
    },
})

export default theme
