import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import BackIcon from '@material-ui/icons/ArrowBack'

import TextField from 'common/components/TextField'
import Button from 'common/components/Button'
import useStyles from './PropertyCreate.styles'
import useForm from 'common/hooks/useForm'
import fields from './PropertyCreate.formfields'
import { Link } from 'common/components/Routing'
import { useApi } from 'api/ApiProvider'
import { useHistory } from 'react-router-dom'

export default function PropertyCreate() {
    const styles = useStyles()
    const history = useHistory()
    const { propertyclient: { createProperty } } = useApi()

    const { values, errors, handleChange, handleSubmit, clearForm } = useForm(create, fields, 'propertycreate')

    function create() {
        createProperty({
            name: values['Name'],
            unitcount: values['Unit Count'],
            address: {
                'street': `${values['Address Line 1']} ${values['Address Line 2']}`,
                'city': values['City'],
                'state': values['State'],
                'zip': values['Zip Code'],
                'country': values['Country'],
            }
        })
            .then(res => {
                clearForm()
                history.push('/properties')
            })
            .catch(err => {
            })
    }

    return (
        <div className={styles.root}>
            <Box m={10} p={3} component={Paper} className={styles.registrationCard}>
                <Link to='/properties'>
                    <Button styletype='text' startIcon={<BackIcon />}>Back</Button>
                </Link>
                <Grid container direction="column" alignItems="center" style={{ height: '100%' }}>
                    <h2>Create Property</h2>
                    <TextField
                        type='text'
                        label='Name'
                        name='Name'
                        value={values['Name']}
                        onChange={handleChange}
                        errormessage={errors.first('Name')}
                    />
                    <TextField
                        type='text'
                        label='Address Line 1'
                        name='Address Line 1'
                        value={values['Address Line 1']}
                        onChange={handleChange}
                        errormessage={errors.first('Address Line 1')}
                    />
                    <TextField
                        type='text'
                        label='Address Line 2'
                        name='Address Line 2'
                        value={values['Address Line 2']}
                        onChange={handleChange}
                        errormessage={errors.first('Address Line 2')}
                    />
                    <TextField
                        type='text'
                        label='City'
                        name='City'
                        value={values['City']}
                        onChange={handleChange}
                        errormessage={errors.first('City')}
                    />
                    <TextField
                        type='text'
                        label='State'
                        name='State'
                        value={values['State']}
                        onChange={handleChange}
                        errormessage={errors.first('State')}
                    />
                    <TextField
                        type='text'
                        label='Zip Code'
                        name='Zip Code'
                        value={values['Zip Code']}
                        onChange={handleChange}
                        errormessage={errors.first('Zip Code')}
                    />
                    <TextField
                        type='text'
                        label='Country'
                        name='Country'
                        value={values['Country']}
                        onChange={handleChange}
                        errormessage={errors.first('Country')}
                    />
                    <TextField
                        type='text'
                        label='Unit Count'
                        name='Unit Count'
                        value={values['Unit Count']}
                        onChange={handleChange}
                        errormessage={errors.first('Unit Count')}
                    />
                    <Button onClick={handleSubmit}>Create Property</Button>
                </Grid>
            </Box>
        </div>
    )
}
