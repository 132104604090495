import useStyles from './ContractPanelEditor.styles.js'

export default function ContractPanelQueue(props) {
    const styles = useStyles(props)()
 
    return (
        <>
            Hi
        </>
    )
}
