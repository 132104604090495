import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        minHeight: '100%',
    },
    registrationCard: {
        backgroundColor: 'white',
        maxWidth: '50em',
        width: '100%',
        padding: '2em 0em'
    },
}))

export default useStyles
