import { makeStyles, alpha } from '@material-ui/core/styles'

const useStyles = props => makeStyles(theme => {
    let styletype = props.styletype

    // Default styles (primary)
    let color = theme.palette.primary.main
    let borderRadius = 4
    let padding = '10px 12px'

    switch(styletype) {
        case 'secondary':
            color = theme.palette.secondary.main
            break
        case 'search':
            borderRadius = 20
            padding = '10px 24px'
            break
        case 'primary':
        default:
            break
    }

    return ({
        error: {
            borderRadius,
            border: `1px solid ${theme.palette.error.main}`,
            boxShadow: `${alpha(color, 0.25)} 0 0 0 0.1rem`,
            transition: theme.transitions.create(['border-color', 'box-shadow'])
        },
        root: {
            width: '100%',
            'label + &': {
                marginTop: theme.spacing(2.5),
            },
        },
        input: {
            borderRadius,
            color: theme.palette.common.black,
            position: 'relative',
            backgroundColor: theme.palette.common.white,
            border: props.errormessage ? '' : '1px solid #ced4da',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            width: '100%',
            padding,
            '&:focus': {
                boxShadow: `${alpha(color, 0.25)} 0 0 0 0.1rem`,
                borderColor: color
            }
        },
        formcontrol: {
            width: '250px',
            margin: `${theme.spacing(.5)}px ${theme.spacing(.5)}px`
        },
        errormessage: {
            color: theme.palette.error.main,
            fontSize: '.8rem',
            margin: '2px 3px'
        }
    })
})

export default useStyles
