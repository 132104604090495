import axios from './axios'

export default class PropertyClient {
    constructor(token) {
        this.axios = axios
        this.axios.defaults.headers.common['authorization'] = `Bearer ${token}`
        this.axios.defaults.baseURL = process.env.REACT_APP_PROPERTY_BASE_URL
        this.appbaseurl = process.env.REACT_APP_APP_BASE_URL
    }

    // Properties
    getPropertyList = () => this.axios.get('property')
    getProperty = (id) => this.axios.get(`property/${id}`)
    createProperty = (property) => this.axios.post('property', property)

    // Contracts
    getPropertyContractList = (id) => this.axios.get(`property/${id}/contracts`)
}
