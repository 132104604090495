import { useState } from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Button from 'common/components/Button'
import { useHistory, useLocation } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import useStyles from './RegistrationWizard.styles'
import RegistrationWizardSwitch, { RegistrationWizardSteps } from './RegistrationWizard.switch'

export default function RegistrationWizard(props) {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')
    const styles = useStyles(props)
    const history = useHistory()
    const location = useLocation()
    let activeStep = 0
    RegistrationWizardSteps.forEach((step, index) => {
        if (location.pathname === step.path)
            activeStep = index
    })

    function handleBack() {
        if (activeStep === 0) {
            history.push('/')
        }
    }

    function handleClose(event, reason) {
        if (reason === 'clickaway')
            return
        setOpen(false)
        setMessage('')
    }

    function openSnackbar(message) {
        setMessage(message)
        setOpen(true)
    }

    return (
        <div className={styles.root}>
            <Box m={10} p={3} component={Paper} className={styles.registrationCard}>
                <Grid container direction="column" alignItems="center" style={{ height: '100%' }}>
                    <Grid item style={{ width: '100%'}}>
                        <Stepper activeStep={activeStep} style={{width: '100%'}}>
                            { RegistrationWizardSteps.map((step, index) => (
                                <Step key={index}>
                                    <StepLabel>{step.label}</StepLabel>
                                </Step>
                            )) }
                        </Stepper>
                    </Grid>
                    <Box my={5} style={{ flexGrow: 1 }}>
                        <RegistrationWizardSwitch onBack={handleBack} openSnackbar={openSnackbar} />
                    </Box>
                </Grid>
            </Box>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                open={open}
                onClose={handleClose}
                message={message}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </div>
    )
}
