import { makeStyles, alpha } from '@material-ui/core/styles'

const useStyles = props => makeStyles(theme => {
    let styletype = props.styletype
    let color = ''

    switch(styletype) {
        case 'secondary':
            color = theme.palette.secondary.main
            break
        case 'primary':
        default:
            color = theme.palette.primary.main
    }

    return ({
        error: {
            borderRadius: 4,
            border: `1px solid ${theme.palette.error.main}`,
            boxShadow: `${alpha(color, 0.25)} 0 0 0 0.1rem`,
            transition: theme.transitions.create(['border-color', 'box-shadow'])
        },
        root: {
            width: '100%',
            'label + &': {
                marginTop: theme.spacing(2.5),
            },
        },
        input: {
            borderRadius: 4,
            color: theme.palette.common.black,
            position: 'relative',
            backgroundColor: theme.palette.common.white,
            border: props.errormessage ? '' : '1px solid #ced4da',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            width: '100%',
            padding: '10px 12px',
            '&:focus': {
                boxShadow: `${alpha(color, 0.25)} 0 0 0 0.1rem`,
                borderColor: color
            }
        },
        formcontrol: {
            width: '250px',
            margin: `${theme.spacing(.5)}px ${theme.spacing(.5)}px`
        },
        errormessage: {
            color: theme.palette.error.main,
            fontSize: '.8rem',
            margin: '2px 3px'
        }
    })
})

export default useStyles
