import { useContext, createContext } from 'react'
import PropertyClient from './PropertyClient'
import { Client as AuthClient } from '@onboardrs/auth-service'
import { useAuth } from 'authentication/AuthProvider'
import { useFirebase } from 'authentication/FirebaseProvider'

// Context
const ApiContext = createContext(null)

// Hook
export const useApi = () => {
    return useContext(ApiContext)
}

// Provider ////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function ApiProvider({ children }) {
    const { firebase } = useFirebase()
    const { user } = useAuth()

    // const { user, firebase } = useAuth()
    // TODO - Be explicit on the methods / members we are exposing
    const apiclients = {
        propertyclient: new PropertyClient(user.token),
        authclient: new AuthClient(firebase, process.env.REACT_APP_AUTH_BASE_URL)
    }

    return (
        <ApiContext.Provider value={{ ...apiclients }}>
            { children }
        </ApiContext.Provider>
    )
}
