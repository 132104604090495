import usePersistentState from 'common/hooks/usePersistentState'
import { useContext, createContext } from 'react'

import { useFirebase } from './FirebaseProvider'

// Context
const AuthContext = createContext(null)

// Hook
export const useAuth = () => {
    return useContext(AuthContext)
}

// Provider ////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function AuthProvider(props) {
    const { firebase } = useFirebase()

    let initialuser = {
        token: 'test',
        id: null,
        firebaseuserid: null,
        haspassword: null,
        emailverified: null,
        email: null,
        firstname: null,
        lastname: null,
        termslastaccepted: null,
        phonenumber: null,
    }

    const [user, setUser] = usePersistentState('prismuser', initialuser)

    return (
        <AuthContext.Provider value={{ initialuser, user, setUser }}>
            { props.children }
        </AuthContext.Provider>
    )
}
