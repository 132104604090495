import styles from './AuthenticatedLayout.module.scss'
import Navbar from 'common/components/Navbar'

export default function AuthenticatedLayout({ children }) {
    return (
        <div className={ styles.root }>
            <Navbar />
            <main className={ styles.content }>
                {children}
            </main>
        </div>
    )
}
