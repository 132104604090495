import React from 'react'
import Grid from '@material-ui/core/Grid'

import TextField from 'common/components/TextField'
import Button from 'common/components/Button'
import { Link } from 'common/components/Routing'

// Component
export default function PropertyListHeader(props) {
    return (
        <Grid
            container
            direction='row'
            alignItems='flex-end'
        >
            <TextField
                placeholder='Search'
                type='text'
                styletype='search'
            />
            <Link to='/properties/create'>
                <Button>Add Property</Button>
            </Link>
        </Grid>
    );
}
