import { Typography, withTheme } from '@material-ui/core'
import MuiButton from '@material-ui/core/Button'
import useStyles from './Button.styles'

const Button = (props) => {
    let theme = props.theme

    // default
    let styleprops = {
        color: theme.palette.common.white,
        hoverColor: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        hoverBackgroundColor: theme.palette.primary.faded,
        width: '18em',
        boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)'
    }

    switch(props.styletype) {
        case 'none':
            styleprops.color = 'inherit'
            styleprops.hoverColor = 'inherit'
            styleprops.backgroundColor = 'inherit'
            styleprops.hoverBackgroundColor = 'inherit'
            styleprops.boxShadow = 'none'
            styleprops.width = 'auto'
            break
        case 'secondary':
            styleprops.backgroundColor = theme.palette.secondary.main
            styleprops.hoverBackgroundColor = theme.palette.secondary.faded
            break
        case 'text':
            styleprops.color = theme.palette.common.black
            styleprops.hoverColor = theme.palette.primary.main
            styleprops.backgroundColor = theme.palette.common.white
            styleprops.hoverBackgroundColor = 'transparent'
            styleprops.boxShadow = 'none'
            styleprops.width = 'auto'
            break
        case 'neutral':
            styleprops.color = theme.palette.common.black
            styleprops.backgroundColor = theme.palette.common.gray
            styleprops.hoverColor = theme.palette.common.black
            styleprops.hoverBackgroundColor = theme.palette.common.gray
            break
        case 'primary':
        default:
            break
    }

    const classes = useStyles(styleprops)()

    return (
        <MuiButton {...props} classes={classes} style={props.styleprops}>
            <span style={props.textstyleprops}>{ props.children }</span>
        </MuiButton>
    )
}

export default withTheme(Button)
