import { useApi } from 'api/ApiProvider'
import { useState, useEffect } from 'react'

export function usePropertyContractList() {
    const [res, setRes] = useState({
        contractPropertyList: [],
        error: null
    })
    const [loading, setLoading] = useState(true)
    const { propertyclient: { getPropertyContractList } } = useApi()

    useEffect(() => {
        let active = true
        getPropertyContractList(16)
            .then(contractPropertyList => {
                if (active) {
                    setRes({ contractPropertyList, error: null })
                    setLoading(false)
                }
            })
            .catch(error => {
                if (active) {
                    setRes({ contractPropertyList: [], error })
                    setLoading(false)
                }
            })

            return () => { active = false }
    }, [getPropertyContractList])

    return { contractPropertyList: res.contractPropertyList, error: res.error, loading }
}
