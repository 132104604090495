import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import useStyles from './ContractPanelQueue.styles.js'
import Button from 'common/components/Button'
import { useState } from 'react'
import Select from 'common/components/Select'
import useEventListener from 'common/hooks/useEventListener.js'
import { IconButton } from '@material-ui/core'
import ContractPanelFileList from '../ContractPanelFileList'
import usePropertyList from 'pages/Properties/PropertyList/usePropertyList'

export default function ContractPanelQueue(props) {
    const [selectable, setSelectable] = useState(false)
    const [keypressed, setKeyPressed] = useState(false)
    const [selectedPropertyId, setSelectedPropertyId] = useState('')
    const styles = useStyles({ selectable })()
    const properties = usePropertyList()
    const propertyOptions = properties.propertyList.map(property => {
        console.log(property)
        return { label: property.name, value: property.id }
    })

    const anyselected = Object.keys(props.categories).length > 0 && Object.keys(props.categories).some(c => props.categories[c].some(f => f.selected))
    const noneselected = Object.keys(props.categories).every(c => props.categories[c].every(f => !f.selected))
    const allselected = Object.keys(props.categories).length > 0 && Object.keys(props.categories).every(c => props.categories[c].every(f => f.selected))

    const handleChange = (e) => setSelectedPropertyId(e.target.value)

    const keyDownHandler = (key) => {
        if (!keypressed) {
            if (key.key === 'Shift') {
                setSelectable(!selectable)
                setKeyPressed(true)
            }
        }
    }
    const keyUpHandler = (key) => {
        if (key.key === 'Shift')
            setKeyPressed(false)
    }

    useEventListener('keydown', keyDownHandler)
    useEventListener('keyup', keyUpHandler)

    return (
        <>
            <div className={styles.selectcontainer}>
                <Button styletype='neutral' textstyleprops={{fontWeight: 'bold'}} styleprops={{width: 'fit-content', padding: '0.2em 1em', height: 'fit-content'}} onClick={() => setSelectable(!selectable)}>{ selectable ? 'Cancel' : 'Select' }</Button>
                { selectable ? (
                    <>
                        <div className={styles.switch}>
                            <span
                                style={{borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}}
                                className={noneselected ? styles.switchActive : styles.switchInactive }
                                onClick={props.onUnselectAll}
                            >
                                None
                            </span>
                            <span
                                style={{borderTopRightRadius: '5px', borderBottomRightRadius: '5px'}}
                                className={allselected ? styles.switchActive : styles.switchInactive }
                                onClick={props.onSelectAll}
                            >
                                All
                            </span>
                        </div>
                        <p onClick={props.onDeleteSelected}>Delete</p>
                    </>
                ) : (
                    <span className={styles.helpertext}>Shift {'\u21E7'}</span>
                ) }
            </div>

            { selectable ? (
                <Select
                    label="Property"
                    value={ selectedPropertyId }
                    options={ propertyOptions }
                    onChange={handleChange}
                />
            ) : null }

            { selectable ? (
                <Button
                    disabled={
                        selectedPropertyId === '' ||
                        selectedPropertyId === '-'
                    }
                >
                    Update
                </Button>
            ) : null }


            <ContractPanelFileList
                onFileSelect={props.onFileSelect}
                onCategorySelect={props.onCategorySelect}
                categories={props.categories}
                selectable={selectable}
            />
        </>
    )
}
