import React from 'react'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import useStyles from './PropertyListTable.styles'

// Component
export default function PropertyListTable(props) {
    const styles = useStyles(props)

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow className={styles.headerRow}>
                        <TableCell>Name</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Units</TableCell>
                        <TableCell>Program Type</TableCell>
                        <TableCell>Program Period</TableCell>
                        <TableCell>Contracts</TableCell>
                        <TableCell>Action Required</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.properties.map((property, index) => (
                        <TableRow key={property.id} className={styles.tableRow} onClick={() => props.onPropertySelect(property.id)}>
                            <TableCell>{property.name}</TableCell>
                            <TableCell>{property.address.street}</TableCell>
                            <TableCell>{property.unitcount}</TableCell>
                            <TableCell>{property.propertytype}</TableCell>
                            <TableCell>{property.programPeriod}</TableCell>
                            <TableCell>{property.contracts}</TableCell>
                            <TableCell>{property.actionRequired}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
