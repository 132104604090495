import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined"
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { useRef, useState } from "react"

import useStyles from './Dropzone.styles.js'
import Button from 'common/components/Button'

export default function Dropzone(props) {
    const [dragover, setDragover] = useState(false)
    const inputfile = useRef(null)
    const styles = useStyles({ dragover })()

    function fileDrop(e) {
        e.preventDefault()
        setDragover(false)
        console.log(e)
        if (e.dataTransfer.items) {
            let files = []
            for (let item of e.dataTransfer.items) {
                files.push(item.getAsFile())
            }
            props.onAddFiles(files)
        }
    }

    function handleDragEnter(e) {
        e.preventDefault()
        setDragover(true)
        console.log('drag enter')
    }

    function handleDragLeave(e) {
        e.preventDefault()
        setDragover(false)
        console.log('drag leave')
    }

    function handleChange(e) {
        console.log(e.target.files)
        if (e.target.files) {
            let files = []
            for (let file of e.target.files) {
                files.push(file)
            }
            props.onAddFiles(files)
        }
    }

    return (
        <div className={ styles.dropzonecontainer }>
            <Box
                mt={15}
                component={Paper}
                elevation={0}
                className={ styles.dropzone }
                style={{ color: 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50%', height: '30em' }}
                onDrop={fileDrop}
                onDragOver={e => e.preventDefault()}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
            >
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    style={{pointerEvents: 'none'}}
                >
                    <CloudUploadOutlinedIcon fontSize="large" className={styles.icon} />
                    <h3 style={{ textAlign: 'center', lineHeight: '2' }}>
                        <span className={styles.dropzonetext}>Drag and Drop Files Here</span>
                        <br />
                        or
                    </h3>
                    <Button
                        styleprops={{
                            pointerEvents: dragover ? 'none' : 'all',
                            fontWeight: 'bold',
                            width: 'auto'
                        }}
                        onClick={() => inputfile.current.click()}
                    >
                        Browse Files
                    </Button>
                    <input
                        type='file'
                        id='file'
                        ref={inputfile}
                        onChange={handleChange}
                        style={{display: 'none'}}
                        multiple
                    />
                </Grid>
            </Box>
            <p>Accepted File types: .doc and .pdf only</p>
        </div>
    )
}