import { makeStyles } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles'

const useStyles = props => makeStyles(theme => {
    let border = '1px dashed gray'
    let boxShadow = 'none'
    let color = '#808080'
    if (props.dragover) {
        border = `1px solid ${theme.palette.primary.faded}`
        boxShadow = `${alpha(theme.palette.primary.faded, 0.25)} 0 0 0 0.3rem`
        color = theme.palette.primary.faded
    }

    return ({
        dropzonecontainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center'
        },
        dropzone: {
            border,
            boxShadow,
            // '&:hover': {
            //     border: `1px solid ${theme.palette.primary.faded}`,
            //     boxShadow: `${alpha(theme.palette.primary.faded, 0.25)} 0 0 0 0.3rem`,
            // }
        },
        icon: {
            color
        },
        dropzonetext: {
            color,
        },
        ctabutton: {
            color: theme.palette.primary.main
        }
    })
})

export default useStyles
