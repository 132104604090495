import { useState, useEffect } from 'react'

// Hook
const usePersistentState = (key, defaultValue) => {
    const [value, setValue] = useState(() => getLocalStorageValue(key, defaultValue))

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value))
    }, [key, value])

    return [value, setValue]
}

// Get value from local storage, coalesced with default value
function getLocalStorageValue(key, defaultValue) {
    const saved = localStorage.getItem(key)
    const initial = JSON.parse(saved)

    return initial || defaultValue
}

export default usePersistentState
