import { useState } from 'react'
import { FormControl, InputBase, InputLabel } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import useStyles from './TextField.styles'

export default function TextField(props) {
    const classes = useStyles(props)()
    const [id] = useState(uniqueId('prefix-'))

    return (
        <FormControl className={classes.formcontrol}>
            <InputLabel shrink htmlFor={id}>{props.label}</InputLabel>
            <InputBase
                id={id}
                {...props}
                spellCheck={props.spellCheck ?? false} // default no spell check
                classes={{ root: classes.root, input: classes.input, error: classes.error }}
                error={props.errormessage ? true : false}
            />
            <span className={classes.errormessage}>{ props.errormessage }</span>
        </FormControl>
    )
}

// Generate unique ids to dynamically associate input label with input base
function gen4() {
    return Math.random().toString(16).slice(-4)
}

function uniqueId(prefix) {
    return (prefix || '').concat([
        gen4(),
        gen4(),
        gen4(),
        gen4(),
        gen4(),
        gen4(),
        gen4(),
        gen4()
    ].join(''))
}
