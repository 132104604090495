import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { useState } from 'react'

import useStyles from './ContractPanel.styles.js'
import Button from 'common/components/Button'
import ContractPanelQueue from '../ContractPanelQueue'
import ContractPanelEditor from '../ContractPanelEditor'

export default function ContractPanel(props) {
    const styles = useStyles(props)()

    return (
        <div className={styles.uploadpanelcontainer}>
            <div className={styles.uploadpanelheader}>
                <Button styletype='none' onClick={() => props.onChangeTab('queue')}>
                    <h2 className={props.activetab === 'queue' ? styles.activetab : styles.tab }>Queue</h2>
                </Button>
                <Button styletype='none' onClick={() => props.onChangeTab('editor')}>
                    <h2 className={props.activetab === 'editor' ? styles.activetab : styles.tab }>Editor</h2>
                </Button>
            </div>
            <div className={styles.uploadpanelbody}>
                { props.activetab === 'queue' ? (
                    <ContractPanelQueue
                        categories={props.categories}
                        onFileSelect={props.onFileSelect}
                        onCategorySelect={props.onCategorySelect}
                        onUnselectAll={props.onUnselectAll}
                        onSelectAll={props.onSelectAll}
                        onDeleteSelected={props.onDeleteSelected}
                        onDeleteFile={(property, i) => props.onDeleteFile(property, i)}
                    />
                ) : <ContractPanelEditor /> }
            </div>
        </div>
    )
}
