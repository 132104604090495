import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        background: theme.palette.background.default,
        minWidth: '100vw',
        minHeight: '100vh',
    },
    registrationCard: {
        backgroundColor: 'white',
        maxWidth: '50em',
        width: '100%',
        maxHeight: '50em'
    },
}))

export default useStyles
