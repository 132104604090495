const fields = {
    'First Name': {
        default: '',
        validations: [
            { required: true },
            { minLength: 2 },
        ],
        persist: true
    },
    'Last Name': {
        default: '',
        validations: [
            { required: true },
            { minLength: 2 }
        ],
        persist: true
    },
    'Password': {
        default: '',
        validations: [
            { required: true },
            { regex: [
                '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
                'Password must be at least 8 characters long with at least one of each of the following: uppercase letter, lowercase letter, number, special character'
            ] }
        ]
    },
    'Password Confirmation': {
        default: '',
        validations: [
            { required: true },
            { custom: (val, values) => val !== values['Password'] ? 'Both passwords entered must match' : null },
        ]
    }
}

export default fields
