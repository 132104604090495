import axiosBase from 'axios'

const axios = axiosBase.create()

axios.defaults.baseURL = process.env.REACT_APP_PROPERTY_BASE_URL

// Add a request interceptor
axios.interceptors.request.use(
    config => {
        // Do something before request is sent
        return config
    },
    error => {
        // Do something with request error
        return Promise.reject(error)
    }
)

// Add a response interceptor
axios.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        console.log(error.message)
        console.log(error.request)
        console.log(error.response)
        const err = error.response
        ? {
            "code": error.response.status,
            "message": error.response.data
        }
        : {
            "code": 500,
            "message": error.message
        }
        // Do something with response error
        return Promise.reject(err)
    }
)

export default axios
